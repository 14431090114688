import React, { useEffect } from "react";
import gsap from "gsap";

const PreLoader: React.FC = () => {
    useEffect(() => {
        // Create a single timeline for all animations
        const tl = gsap.timeline();

        const preLoaderAnim = () => {
            // Set initial positions for animation - completely invisible
            gsap.set(".preloader-word", { 
                y: 70,
                opacity: 0
            });
            
            // Animate words in after a delay
            tl.to(".preloader-word", {
                duration: 0.8,
                y: 0,
                opacity: 1,
                stagger: 0.2,
                ease: "power2.out",
                delay: 0.5 // Add a small delay before text appears
            })
            // Hold for 1.5 seconds
            .to({}, { duration: 1.5 })
            // Animate words out (smoother exit)
            .to(".preloader-word", {
                duration: 0.6,
                y: -30,
                opacity: 0,
                stagger: 0.1,
                ease: "power2.in"
            })
            // Enable scrolling
            .to("body", {
                duration: 0.1,
                css: { overflowY: "scroll" },
                ease: "none"
            })
            // Animate preloader away
            .to(".preloader", {
                duration: 0.8,
                height: "0vh",
                ease: "power3.inOut",
                onComplete: mobileLanding
            })
            .set(".preloader", { display: "none" });
        };

        const mobileLanding = () => {
            if (window.innerWidth < 763) {
                const landingElement = document.querySelector(".landing__main2");
                if (landingElement) {
                    gsap.from(".landing__main2", {
                        duration: 0.8,
                        opacity: 0,
                        y: 30,
                        ease: "power2.out"
                    });
                }
            }
        };

        // Start with a very small delay to ensure DOM is ready
        setTimeout(preLoaderAnim, 100);
        
        return () => {
            // Clean up by killing animation
            tl.kill();
        };
    }, []);

    return (
        <div
            className="preloader"
            style={{
                height: "100vh",
                width: "100%",
                background: "#000000", 
                color: "#e5ebf2",
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 55,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
            }}
        >
            <div
                style={{
                    display: "flex",
                    height: "60px",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    fontWeight: "bold",
                    overflow: "hidden"
                }}
            >
                {/* Make text completely invisible in initial render with inline styles */}
                <span className="preloader-word" style={{color: "#e4ded7", fontSize: "24px", opacity: 0}}>Web Developer</span>
                <span className="preloader-word" style={{color: "#e4ded7", fontSize: "24px", opacity: 0}}> & </span>
                <span className="preloader-word" style={{color: "#e4ded7", fontSize: "24px", opacity: 0}}>Technologist</span>
            </div>
        </div>
    );
};

export default PreLoader;